<template>
  <div class="main-container" v-if="!initLoading">
    <div class="tool-container">
      <div>
        <a-checkbox v-model:checked="jiduContactChecked">{{ $t('show_jidu_contact_info') }}</a-checkbox>
        <br />
        <a-radio-group v-model:value="languageValue">
          <a-radio :value="1">{{ $t('preview_chi_eng_version') }}</a-radio>
          <a-radio :value="2">{{ $t('preview_eng_version') }}</a-radio>
        </a-radio-group>
      </div>
      <div>
        <a-button type="primary" v-print="printObj">{{ $t('print') }}</a-button>&nbsp;<a-button type="primary" @click="downloadPdf">{{ $t('download_pdf') }}</a-button>
      </div>
    </div>
    <a-divider/>
    <div class="print-container">
      <div id="print-container">
        <template v-if="languageValue === 1">
          <div class="large-size"><strong>酒店入住单</strong> <strong class="copy">Hotel Voucher</strong></div>
          <div class="line-height">请在抵达时出示本单据 <span class="copy">Please present this voucher upon arrival.</span></div>
          <hr class="divider"/>
          <template v-if="!isEmpty(orderInfo.confirm_no)">
            <div class="line-height">酒店确认号 <span class="copy"> Hotel Confirmation Code</span>: <strong>{{ orderInfo.confirm_no }}</strong></div>
          </template>
          <table class="table-border">
            <thead>
            <tr>
              <td class="line-height title">酒店信息 <span class="copy">Hotel Information</span></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div class="line-height"><strong class="medium-size">{{ orderInfo.hotel_name_cn + ' ' + orderInfo.hotel_name_en }}</strong></div>
                <div class="line-height" v-if="!isEmpty(orderInfo.hotel_tel)"><span class="copy">电话 Telephone:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_tel }}</div>
                <div class="line-height"><span class="copy">地址 Address:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ isEmpty(orderInfo.hotel_address_cn) ? orderInfo.hotel_address_en : orderInfo.hotel_address_cn }}</div>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="table-border">
            <thead>
            <tr>
              <td class="line-height title" colspan="3">订单信息 <span class="copy">Order Information</span></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width:37%">
                <div class="line-height">订单号 <span class="copy">Booking Id</span></div>
                <div class="line-height">{{ orderInfo.order_id }}</div>
              </td>
              <td style="width:33%">
                <div class="line-height">入住日期 <span class="copy">Check-In</span></div>
                <div class="line-height">{{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }}</div>
              </td>
              <td style="width:30%">
                <div class="line-height">离店日期 <span class="copy">Check-Out</span></div>
                <div class="line-height">{{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="line-height">房型 <span class="copy">Room Type</span></div>
                <div class="line-height" v-if="!isEmpty(orderInfo.room_name_cn)">{{ orderInfo.room_name_cn }}</div>
                <div class="line-height" v-if="!isEmpty(orderInfo.room_name_en)">{{ orderInfo.room_name_en }}</div>
                <div class="line-height" v-else>{{ orderInfo.room_name }}</div>
              </td>
              <td>
                <div class="line-height">房间数 <span class="copy">Unit</span></div>
                <div class="line-height">{{ orderInfo.PaxRoom.Count }}</div>
              </td>
              <td>
                <div class="line-height">餐型 <span class="copy">Meal Type</span></div>
                <div class="line-height">{{ $t('mealtype_unit_cn.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
                <div class="line-height">{{ $t('mealtype_unit_en.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
              </td>
            </tr>
            <tr v-if="EExtraData?.bed_group_id">
              <td colspan="3">
                <div class="line-height">备注床型 <span class="copy">Select bed type</span></div>
                {{ EExtraData.ERateInfo?.bed_groups[EExtraData.bed_group_id].description }}<span class="tip1">(酒店将优先为您安排选择的床型，但无法保证一定满足)</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="line-height">每间入住 <span class="copy">Per Room</span></div>
                <div class="line-height">
                  {{ $t('adult_unit_cn', {'n': orderInfo.PaxRoom.Adults}) }},
                  {{ $t('child_unit_cn', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                  <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                    (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_cn', {n: age}) }}</template>)
                  </template>
                </div>
                <div class="line-height">
                  {{ $t('adult_unit_en', {'n': orderInfo.PaxRoom.Adults}) }},
                  {{ $t('child_unit_en', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                  <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                    (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_en', {n: age}) }}</template>)
                  </template>
                </div>
              </td>
              <td colspan="2">
                <div class="line-height">入住人（名/姓）<span class="copy">Guests(First / Last)</span></div>
                <div class="line-height"><template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
                  {{name.FirstName}} {{name.LastName}};
                </template></div>
              </td>
            </tr>
            <tr v-if="!isEmpty(orderInfo.special)">
              <td colspan="3">
                <div class="line-height">特殊需求 <span class="copy">Customer Requests</span></div>
                <div class="line-height">{{ orderInfo.special }}</div>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="table-border" v-if="this.eOccPricing">
            <thead>
            <tr>
              <td class="line-height title" colspan="4">价格详细 <span class="copy">Price Details</span></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width:25%" v-if="!hotelPackage">
                <div class="line-height">房费 <span class="copy">Room Rate</span></div>
                <div class="line-height">{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ eOccPricing.totals.exclusive.request_currency.value * roomCount }}</div>
              </td>
              <td style="width:25%" v-if="!hotelPackage && calTaxFree(eOccPricing) > 0">
                <div class="line-height">税费 <span class="copy">Taxes and Fees</span></div>
                <div class="line-height">{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ $filters.formatAmount(calTaxFree(eOccPricing) * roomCount, 2) }}</div>
              </td>
              <td style="width:25%" v-if="!hotelPackage && calSumPrice(eOccPricing, 'sales_tax') > 0">
                <div class="line-height">销售税 <span class="copy">Sales Tax</span></div>
                <div class="line-height">{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ $filters.formatAmount(calSumPrice(eOccPricing, 'sales_tax') * roomCount, 2) }}</div>
              </td>
              <td style="width:25%" v-if="eOccPricing.totals?.property_fees && eOccPricing.totals.property_fees.billable_currency.value > 0">
                <div class="line-height">到店支付 <span class="copy">Pay at hotel</span></div>
                <div class="line-height">{{ eOccPricing.totals.property_fees.billable_currency.currency }} {{ eOccPricing.totals.property_fees.billable_currency.value * roomCount }}</div>
              </td>
              <td style="width:25%" v-if="!hotelPackage">
                <div class="line-height">预付金额 <span class="copy">Prepaid amount</span></div>
                <div class="line-height">{{ eOccPricing.totals.inclusive.request_currency.currency }} {{ Decimal.add(eOccPricing.totals.property_fees?eOccPricing.totals.property_fees.request_currency.value:0, eOccPricing.totals.inclusive.request_currency.value) * roomCount }}</div>
              </td>
            </tr>
            <tr v-if="!hotelPackage && (calTaxFree(eOccPricing) > 0 || calSumPrice(eOccPricing, 'sales_tax') > 0)">
              <td colspan="4">
                <div class="line-height">税费说明: 该费用包括旅行服务供应商（即酒店、租车公司）和/或我们针对您的预订向税务部门支付的税款估算金额（包括但不限于销售、住宿和增值税）。该金额可能还包括我们支付的度假费、清洁费及其他费用和/或我们、酒店供应商和/或您预订的网站为我们和/或他们提供的服务所收取的费用，具体取决于地点、金额及预订方式等因素。有关更多详细信息，请参阅条款和条件。</div>
              </td>
            </tr>
            <tr v-if="eOccPricing.totals?.property_fees && eOccPricing.totals.property_fees.billable_currency.value > 0">
              <td colspan="4">
                <div class="line-height">到店支付说明: 酒店将以当地货币收取税费或者额外强制的费用。到店支付的税费是基于旅行时汇率计算得出的，可能会有所不同。18周岁以下儿童随行时，可能适用不一样的政策。</div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="tip">
            <div>
              <div class="center-between"><span>入住办理时间：{{ orderInfo.hotelPolicy.begin_time }} - {{ orderInfo.hotelPolicy.end_time }}</span><span>退房时间：{{ orderInfo.hotelPolicy.checkout_time }}</span><span>单独办理入住需年满{{ orderInfo.hotelPolicy.min_age }}岁</span><span></span></div>
            </div>
            <div v-if="orderInfo.hotelPolicy.special_instructions">
              <div class="line-height"><strong>特别入住说明:</strong></div>
              <div class="ti28" v-html="orderInfo.hotelPolicy.special_instructions"></div>
            </div>
            <div v-if="orderInfo.hotelPolicy.instructions">
              <div class="line-height"><strong>入住说明:</strong></div>
              <div class="ex-content3" v-html="orderInfo.hotelPolicy.instructions">
              </div>
            </div>
            <div v-if="orderInfo.hotelPolicy.fee_mandatory">
              <div class="line-height"><strong>强制税费:</strong></div>
              <div class="ex-content3" v-html="orderInfo.hotelPolicy.fee_mandatory">
              </div>
            </div>
            <div v-if="orderInfo.hotelPolicy.fee_optional">
              <div class="line-height"><strong>可选项目费用:</strong></div>
              <div class="ex-content3" v-html="orderInfo.hotelPolicy.fee_optional">
              </div>
            </div>
            <div v-if="orderInfo.hotelPolicy.policie_know_before_you_go">
              <div class="line-height"><strong>入住须知:</strong></div>
              <div class="ex-content3" v-html="orderInfo.hotelPolicy.policie_know_before_you_go">
              </div>
            </div>
          </div>
          <template v-if="jiduContactChecked">
            <hr class="divider"/>
            <div class="jidu-info">
              <div>
                如果您对此订单有有任何疑问，请通过以下方式联系我们：<br />
                电话: 15541156694 (仅限紧急联系) <br />
                Email: cs@jidutravel.com
              </div>
              <a-image src="/images/logo_zh_CN.png" :preview="false" :width="150"></a-image>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="large-size"><strong>Hotel Voucher</strong></div>
          <div class="line-height">Please present this voucher upon arrival.</div>
          <hr class="divider"/>
          <template v-if="!isEmpty(orderInfo.confirm_no)">
            <div class="line-height">Hotel Confirmation Code: <strong>{{ orderInfo.confirm_no }}</strong></div>
          </template>
          <table class="table-border">
            <thead>
            <tr>
              <td class="line-height title">Hotel Information</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div class="line-height"><strong class="medium-size">{{ orderInfo.hotel_name_en }}</strong></div>
                <div class="line-height" v-if="!isEmpty(orderInfo.hotel_tel)"><span class="copy">Telephone:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_tel }}</div>
                <div class="line-height"><span class="copy">Address:&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ orderInfo.hotel_address_en }}</div>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="table-border">
            <thead>
            <tr>
              <td class="line-height title" colspan="3">Order Information</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width:37%">
                <div class="line-height">Booking Id</div>
                <div class="line-height">{{ orderInfo.order_id }}</div>
              </td>
              <td style="width:33%">
                <div class="line-height">Check-In</div>
                <div class="line-height">{{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }}</div>
              </td>
              <td style="width:30%">
                <div class="line-height">Check-Out</div>
                <div class="line-height">{{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="line-height">Room Type</div>
                <div class="line-height" v-if="!isEmpty(orderInfo.room_name_en)">{{ orderInfo.room_name_en }}</div>
                <div class="line-height" v-else>{{ orderInfo.room_name }}</div>
              </td>
              <td>
                <div class="line-height">Unit</div>
                <div class="line-height">{{ orderInfo.PaxRoom.Count }}</div>
              </td>
              <td>
                <div class="line-height">Meal Type</div>
                <div class="line-height">{{ $t('mealtype_unit_en.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}</div>
              </td>
            </tr>
            <tr v-if="EExtraData?.bed_group_id">
              <td colspan="3">
                <div class="line-height">Select bed type</div>
                {{ EExtraData.ERateInfo?.bed_groups[EExtraData.bed_group_id].description }}<span class="tip1">(The hotel will give priority to your choice of bed type, but there is no guarantee that it will be satisfied)</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="line-height">Per Room</div>
                <div class="line-height">
                  {{ $t('adult_unit_en', {'n': orderInfo.PaxRoom.Adults}) }},
                  {{ $t('child_unit_en', {'n': orderInfo.PaxRoom?.Children?.length}) }}
                  <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
                    (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old_en', {n: age}) }}</template>)
                  </template>
                </div>
              </td>
              <td colspan="2">
                <div class="line-height">Guests(First / Last)</div>
                <div class="line-height"><template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
                  {{name.FirstName}} {{name.LastName}};
                </template></div>
              </td>
            </tr>
            <tr v-if="!isEmpty(orderInfo.special)">
              <td colspan="3">
                <div class="line-height">Customer Requests</div>
                <div class="line-height">{{ orderInfo.special }}</div>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="table-border" v-if="this.eOccPricing">
            <thead>
            <tr>
              <td class="line-height title" colspan="4">Price Details</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width:25%" v-if="!hotelPackage">
                <div class="line-height">Room Rate</div>
                <div class="line-height">{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ eOccPricing.totals.exclusive.request_currency.value * roomCount }}</div>
              </td>
              <td style="width:25%" v-if="!hotelPackage">
                <div class="line-height">Taxes and Fees</div>
                <div class="line-height">{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ $filters.formatAmount(calTaxFree(eOccPricing) * roomCount, 2) }}</div>
              </td>
              <td style="width:25%" v-if="eOccPricing.totals?.property_fees && eOccPricing.totals.property_fees.billable_currency.value > 0">
                <div class="line-height">Pay at hotel</div>
                <div class="line-height">{{ eOccPricing.totals.property_fees.billable_currency.currency }} {{ eOccPricing.totals.property_fees.billable_currency.value * roomCount }}</div>
              </td>
              <td style="width:25%" v-if="!hotelPackage">
                <div class="line-height">Prepaid amount</div>
                <div class="line-height">{{ eOccPricing.totals.inclusive.request_currency.currency }} {{ Decimal.add(eOccPricing.totals.property_fees?eOccPricing.totals.property_fees.request_currency.value:0, eOccPricing.totals.inclusive.request_currency.value) * roomCount }}</div>
              </td>
            </tr>
            <tr v-if="calTaxFree(eOccPricing) > 0 && !hotelPackage">
              <td colspan="4">
                <div class="line-height">Taxes and Fees: This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.</div>
              </td>
            </tr>
            <tr v-if="eOccPricing.totals?.property_fees && eOccPricing.totals.property_fees.billable_currency.value > 0">
              <td colspan="4">
                <div class="line-height">Pay at hotel: Taxes and/or property-imposed fees of mandatory extras will be collected by the property, in the property's local currency. Taxes and/or fees due at the property are based on current exchange rates, which may vary at the time of travel. A different policy may be applied when traveling with children whose age is under 18 years old.</div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="tip">
            <div>
              <div class="center-between"><span>Check-in time from：{{ orderInfo.hotelPolicy.begin_time }} - {{ orderInfo.hotelPolicy.end_time }}</span><span>Check-out Time：{{ orderInfo.hotelPolicy.checkout_time }}</span><span>Minimum check-in age - {{ orderInfo.hotelPolicy.min_age }}</span><span></span></div>
            </div>
            <div v-if="orderInfo.hotelPolicy.special_instructions">
              <div class="line-height"><strong>Special Check-in Instructions:</strong></div>
              <div class="ti28" v-html="orderInfo.hotelPolicy.special_instructions"></div>
            </div>
            <div v-if="orderInfo.hotelPolicy.instructions">
              <div class="line-height"><strong>Check-in Instructions:</strong></div>
              <div class="ex-content3" v-html="orderInfo.hotelPolicy.instructions">
              </div>
            </div>
            <div v-if="orderInfo.hotelPolicy.fee_mandatory">
              <div class="line-height"><strong>Mandatory Fees:</strong></div>
              <div class="ex-content3" v-html="orderInfo.hotelPolicy.fee_mandatory">
              </div>
            </div>
            <div v-if="orderInfo.hotelPolicy.fee_optional">
              <div class="line-height"><strong>Optional Extras:</strong></div>
              <div class="ex-content3" v-html="orderInfo.hotelPolicy.fee_optional">
              </div>
            </div>
            <div v-if="orderInfo.hotelPolicy.policie_know_before_you_go">
              <div class="line-height"><strong>Know Before You Go:</strong></div>
              <div class="ex-content3" v-html="orderInfo.hotelPolicy.policie_know_before_you_go">
              </div>
            </div>
          </div>
          <template v-if="jiduContactChecked">
            <hr class="divider"/>
            <div class="jidu-info">
              <div>
                Should you have any questions about this booking, please contact us via:<br />
                Tel: 15541156694 (Emergency contact only) <br />
                Email: cs@jidutravel.com
              </div>
              <a-image src="/images/logo_en_US.png" :preview="false" :width="150"></a-image>
            </div>
          </template>
        </template>
      </div>
    </div>
    <a-divider/>
    <div class="tool-container">
      <div>
      </div>
      <div>
        <a-button type="primary" v-print="printObj">{{ $t('print') }}</a-button>&nbsp;<a-button type="primary" @click="downloadPdf">{{ $t('download_pdf') }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import print from 'vue3-print-nb'
import {inject} from "vue";
import {Modal} from "ant-design-vue";
import dayjs from "dayjs";
import {isEmpty, getEmptyValue, isERoom} from "@/utils/util";
import {getInfo} from "@/services/booking";
import {OrderHotelStatus} from "@/data/order-hotel-status";
import html2Canvas from 'html2canvas'
import jsPDF from 'jspdf'
import Decimal from "decimal.js";

export default {
  name: "BookingVoucherEps",
  directives: {
    print
  },
  data(){
    const orderId = this.$route.params?.OrderId
    if(isEmpty(orderId))
      location.href = '/home'

    document.title = this.$t('hotel_voucher') + ' ' + orderId + ' - ' + this.$t('app_name')
    const isZhCn = this.$store.getters["setting/getLanguage"] === 'zh_CN'
    const languageValue = isZhCn ? 1 : 2
    this.getOrderInfo(orderId, languageValue)

    return {
      dayjs,
      Decimal,
      checkInOutFormat: 'YYYY-MM-DD',
      showHeaderFooter: inject('showHeaderFooter'),
      isZhCn,
      orderId,
      initLoading: true,
      orderInfo: undefined,
      OrderHotelStatus,
      jiduContactChecked: true,
      languageValue,
      eOccPricing: null,
      roomCount: 1,
      hotelPackage: false,
      printObj: {
        id: 'print-container',
        previewTitle: document.title,
        popTitle: document.title,
      },
    }
  },
  beforeMount() {
    this.showHeaderFooter = false
  },
  watch: {
    languageValue: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getOrderInfo(this.orderId, newValue)
      }
    }
  },
  methods: {
    isEmpty,
    getEmptyValue,
    getOrderInfo(orderId, languageValue){
      let language = languageValue === 1 ? 'zh_CN':'en_US'
      getInfo(orderId, language).then(res => {
        this.initLoading = false
        const resData = res.data
        if (resData.Success) {
          this.orderInfo = resData.Data
          this.orderInfo.PaxRoom = JSON.parse(this.orderInfo.pax_room_json)
          if(!this.orderInfo.PaxRoom.Children)
            this.orderInfo.PaxRoom.Children = []

          this.roomCount = this.orderInfo.PaxRoom.Count

          if(isERoom(this.orderInfo.rate_plan)){
            this.EExtraData = this.orderInfo.ExtraData
            if(this.EExtraData.AirTicketInfo)
              this.EExtraData.AirTicketInfo = JSON.parse(this.EExtraData.AirTicketInfo)
            if(this.EExtraData.rate)
              this.EExtraData.ERateInfo = JSON.parse(this.EExtraData.rate)
            this.eOccPricing = Object.values(this.EExtraData.ERateInfo.occupancy_pricing)[0]
            this.hotelPackage = this.EExtraData.sales_environment === 'hotel_package'
            this.EExtraData.rate = undefined
            this.orderInfo.ExtraData = undefined
          }
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
          });
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getPdf(title) {
      html2Canvas(document.querySelector('#print-container'), {
        allowTaint: false,
        taintTest: false,
        logging: false,
        useCORS: true,
        dpi: window.devicePixelRatio*4, //将分辨率提高到特定的DPI 提高四倍
        scale:4 //按比例增加分辨率
      }).then(canvas=>{
        let pdf = new jsPDF('p', 'mm', 'a4');    //A4纸，纵向
        let ctx = canvas.getContext('2d'),
            a4w = 190, a4h = 277,    //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
            imgHeight = Math.floor(a4h * canvas.width / a4w),    //按A4显示比例换算一页图像的像素高度
            renderedHeight = 0;

        while(renderedHeight < canvas.height) {
          let page = document.createElement("canvas");
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight);//可能内容不足一页

          //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
          pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width));    //添加图像到页面，保留10mm边距

          renderedHeight += imgHeight;
          if(renderedHeight < canvas.height) {
            pdf.addPage();//如果后面还有内容，添加一个空页
          }
          // delete page;
        }
        //保存文件
        pdf.save(title + '.pdf')
      })
    },
    downloadPdf(){
      this.getPdf(document.title)
    },
    calRoomAdditionRate(eoccPricing){
      let sum = 0
      eoccPricing.nightly.forEach(nightly => {
        nightly.filter(item => item.type === 'base_rate').forEach(p => {
          sum += p.value
        })
      })

      return eoccPricing.totals.exclusive.request_currency.value - sum
    },
    calTaxFree(eoccPricing){
      let sum = 0
      eoccPricing.nightly.forEach(nightly => {
        nightly.filter(item => item.type === 'tax_and_service_fee' || item.type === 'recovery_charges_and_fees').forEach(p => {
          sum += p.value
        })
      })
      eoccPricing?.stay?.filter(item => item.type === 'tax_and_service_fee' || item.type === 'recovery_charges_and_fees').forEach(p => {
        sum += p.value
      })
      return sum
    },
    calSumPrice(eoccPricing, type){
      let sum = 0
      eoccPricing.nightly.forEach(nightly => {
        nightly.filter(item => item.type === type).forEach(p => {
          sum += p.value
        })
      })
      eoccPricing?.stay?.filter(item => item.type === type).forEach(p => {
        sum += p.value
      })
      return sum
    },
    calAdditionRate(eoccPricing){
      let d3 = Decimal.sum(eoccPricing.totals.exclusive.request_currency.value, this.calTaxFree(eoccPricing), this.calSumPrice(eoccPricing, 'sales_tax'), this.calSumPrice(eoccPricing, 'property_fee')).toPrecision(2)
      return Decimal.sub(eoccPricing.totals.inclusive.request_currency.value, d3)
    }
  }
}
</script>

<style>
.main-container {
  max-width: 750px;
  margin: 20px auto;
  font-size: 12px;
  line-height: 25px;
}
.main-container .ant-divider-horizontal {
  margin: 0 0 10px 0;
}
.tool-container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items:center;
}
.print-container {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.divider {
  border: 0;
  height: 0;
  border-bottom: 2px solid #000000;
}
.copy {
  color: rgba(0, 0, 0, 0.7);
}
.large-size {
  font-size: 16px;
}
.medium-size {
  font-size: 14px;
}
.title {
  background-color: #FAFAFA;
}
.table-border {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 10px;
}
.table-border td {
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  vertical-align: top;
}
.line-height {
  line-height: 25px;
}
.tip {
  padding: 10px 20px;
  font-size: 11px;
}
.tip1 {
  padding-left: 15px;
  font-size: 12px;
  font-style: italic;
}
.jidu-info {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  line-height: 20px;
}


.ex-content3 {
  line-height: 25px;
}
.ex-content3 p {
  width:100%;
  margin: 0px;
}
.ex-content3 ul {
  width:100%;
  margin: 0px 0px 0px -18px;
  display: inline;
}
.ex-content3 li {
  margin: 0px;
  display: inline;
}
</style>